module.exports = {
    '<<=': true
  , '>>=': true
  , '++': true
  , '--': true
  , '+=': true
  , '-=': true
  , '*=': true
  , '/=': true
  , '%=': true
  , '&=': true
  , '^=': true
  , '|=': true
  , '=': true
}
